import { credentialReviewService } from "../_services";
import moment from "moment";

function CompoundStatuses(ruleStatus, exemStatus)
  {
    switch(exemStatus)
    {
      case "Unsubmitted":
        if(ruleStatus == "")
          ruleStatus = "Unsubmitted";
        break;
      case "Received":
        if(ruleStatus != "Accepted")
          ruleStatus = "Received";
        break;
      case "Denied":
        if(ruleStatus != "Accepted" && ruleStatus != "Received")
          ruleStatus = "Denied";
        break;
      case "Accepted":
        ruleStatus = "Accepted"
        break;
    }
    return ruleStatus;
  }

  function complexExemFinder(state, trade, exemId)
  {
    //returns an object containing the OrRule, and or or, and setID
    if(state.iframeOrder.ComplexOrList[trade] !== undefined)
    {
      for(const COrID in state.iframeOrder.ComplexOrList[trade] || {})
      {
        const COrSet = state.iframeOrder.ComplexOrList[trade][COrID];

        for(const setID in COrSet.exemptionSets)
        {
          const exSet = COrSet.exemptionSets[setID]
          if(exSet.orExemptions[exemId])
          {
            return {OrRule: COrSet.id, AndOr: "or", SetID: exSet.id};
          }

          if(exSet.andExemptions[exemId])
          {
            return {OrRule: COrSet.id, AndOr: "and", SetID: exSet.id};
          }
        }
      }
    }
  }

const state = {
  
  reviewOrders:
  {
    getOrderProductLoading: null,
    getOrdersLoading: null,
    getOrderPageCount: null,
    getCredentialReviewOrderFastPassLoading: null,
    getFastPassOrderLoading: null,
    getOrderDetailsLoading: null,
    getOrderByKeyLoading: null,
    getOrderRuleSetLoading: null,
    getOrderProductsByKeyLoading: null,
    uploadDataLoading: null,
    formTemplateLoading: null,
    adminFormTemplateLoading: null,
    submitFormLoading: null,
    retrieveFileLoading: null,
    retrieveFormLoading: null,
    decisionLoading: null,
    commentsLoading: null,
    submissionLoading:null,
    finalizeSubmissionLoading: null,
    updateLinkExpirationLoading: null,
    IDmeVerifyLoading: null,
    integrationsLoading: null,
    OrderFulfillmentIntegrationsLoading: null,
  },
  orders: null,
  orderDetails: {},
  orderDetailComments: {},
  orderProducts: [],
  FastPass: [],
  FastPassOrder: {},
  orderAddress: {
    BillingAddress: null,
    ShippingAddress: null
  },
  filter:
  {
    Page: 1,
    Name: null,
    Statuses: null,
    Before: null,
    After: null,
    OrderNums: null
  },
  pageCount: 1,
  iframeOrder:
  {
    DateUTC: null,
    ID:null,
    Name: null,
    CustomerID: null,
    OrderID: null,
    OutcomeType: null,
    SystemDisplayOrderID: null,
    SubscriberID: null,
    Products: {},
    BasicAndList: {},
    BasicOrList: {},
    ComplexOrList: {},
    ExemDupeTracker: {1: {},2: {},3: {}},
  },
  FormTemplates: {},
  integrations: {},
  OrderFulfillmentIntegrations: {},
  RuleSet: {},
  imgString: "",
  imgFileID: 1,
  form: [],
  retrievalError: "",
  adminFormTemplate: {},
  adminSubmitStatus: "None",
  submitStatus: "None",
  currentExemStatus: "Unsubmitted",
};


const actions = {
  getCredentialReviewOrders({ dispatch, commit }, page) {
    commit("getCredentialReviewOrdersRequest");

    credentialReviewService.getCredentialReviewOrders(page).then(
      response => {
        return commit("getCredentialReviewOrdersSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrdersFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  
  getCredentialReviewOrderPageCount({ dispatch, commit }, filters) {
    commit("getCredentialReviewOrderPageCountRequest");

    credentialReviewService.getCredentialReviewOrderPageCount(filters).then(
      response => {
        commit("getCredentialReviewOrderPageCountSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderPageCountFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getCredentialReviewOrderFastPass({ dispatch, commit }, filters) {
    commit("getCredentialReviewOrderFastPassRequest");

    credentialReviewService.getCredentialReviewOrderFastPass(filters).then(
      response => {
        commit("getCredentialReviewOrderFastPassSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderFastPassFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getCredentialReviewOrderFastPassOrder({ dispatch, commit }, id) {
    commit("getCredentialReviewOrderFastPassOrderRequest");

    credentialReviewService.getCredentialReviewOrder(id).then(
      response => {
        commit("getCredentialReviewOrderFastPassOrderSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderFastPassOrderFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getCredentialReviewOrderDetails({ dispatch, commit }, credentialOrderObject) {
    commit("getCredentialReviewOrderDetailsRequest");

    credentialReviewService.getCredentialReviewOrderDetails(credentialOrderObject).then(
      response => {
        commit("getCredentialReviewOrderDetailsSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderDetailsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getOrderFulfillmentIntegrations({ dispatch, commit }, id) {
    commit("getOrderFulfillmentIntegrationsRequest");

    credentialReviewService.getCredentialReviewOrderIntegrations(id).then(
      response => {
        commit("getOrderFulfillmentIntegrationsSuccess", response);
      },
      error => {
        commit("getOrderFulfillmentIntegrationsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getCredentialReviewOrderAddress({ dispatch, commit }, credentialOrderObject) {
    commit("getCredentialReviewOrderAddressRequest");

    credentialReviewService.getCredentialReviewOrderAddress(credentialOrderObject).then(
      response => {
        commit("getCredentialReviewOrderAddressSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderAddressFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  getCredentialReviewOrderProducts({ dispatch, commit }, credentialOrderObject) {
    commit("getCredentialReviewOrderProductsRequest");

    credentialReviewService.getCredentialReviewOrderProducts(credentialOrderObject).then(
      response => {
        commit("getCredentialReviewOrderProductsSuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderProductsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getOrderRuleSet({ dispatch, commit }, key) {
    commit("getOrderRuleSetRequest");
  
    credentialReviewService.getOrderRuleSet(key).then(
    response => {
      commit("getOrderRuleSetSuccess", response);
    },
    error => {
      commit("getOrderRuleSetFailure", error);
      dispatch("alert/error", error, { root: true });
    }
    );
  },
  //credential retrieval
  getCredentialReviewExemFile({ dispatch, commit }, CredRetrievalObject) {
    commit("RetrieveFileRequest");
  
    credentialReviewService.getCredentialReviewExemFile(CredRetrievalObject).then(
    response => {
      commit("RetrieveFileSuccess", response);
    },
    error => {
      commit("RetrieveFileFailure", error);
      dispatch("alert/error", error, { root: true });
    }
    );
  },

  getCredentialReviewExemForm({ dispatch, commit }, CredRetrievalObject) {
    commit("RetrieveFormRequest");
  
    credentialReviewService.getCredentialReviewExemForm(CredRetrievalObject).then(
    response => {
      commit("RetrieveFormSuccess", response);
    },
    error => {
      commit("RetrieveFormFailure", error);
      dispatch("alert/error", error, { root: true });
    }
    );
  },

  getAdminFormTemplate({ dispatch, commit }, id) {
    commit("adminFormTemplateRequest");
    credentialReviewService.getSingleFormtemplate(id).then(
      response => {
        commit("adminFormTemplateSuccess", response);
      },
      error => {
        commit("adminFormTemplateFailure");
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  submitAdminForm({ dispatch, commit }, toSend) {
    commit("uploadAdminFormRequest");
    credentialReviewService.adminSubmitForm(toSend).then(
      response => {
        commit("uploadAdminFormSuccess", response);
      },
      error => {
        commit("uploadAdminFormFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  uploadAdminData({ dispatch, commit }, formData) {
    commit("AdminUploadDataRequest");
    credentialReviewService.adminUploadData(formData).then(
      response => {
        commit("AdminUploadDataSuccess", response);
      },
      error => {
        commit("AdminUploadDataFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  Decide({ dispatch, commit }, DecisionObject)
  {
    commit("DecideRequest");

    credentialReviewService.Decide(DecisionObject).then(
      response => {
        commit("DecideSuccess", {response, DecisionObject});
        dispatch("getComments", {OrderID: DecisionObject.OrderID, ExemID: DecisionObject.ExemptionID})
      },
      error => {
        commit("DecideFailure", error);
        dispatch("alert/error", error, { root: true });
      }
      );
  },

  getComments({ dispatch, commit }, {OrderID, ExemID})
  {
    commit("getCommentsRequest");

    credentialReviewService.getComments({OrderID, ExemID}).then(
      response => {
        commit("getCommentsSuccess", response);
      },
      error => {
        commit("getCommentsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
      );
  },

  updateLinkExpiration({ dispatch, commit }, OrderID)
  {
    commit("updateLinkExpirationRequest");

    credentialReviewService.updateExpiration(OrderID).then(
      response => {
        commit("updateLinkExpirationSuccess", response);
      },
      error => {
        commit("updateLinkExpirationFailure", error);
        dispatch("alert/error", error, { root: true });
      }
      );
  },

  //for iframe
  getCredentialReviewOrderByKey({ dispatch, commit }, key) {
    commit("getCredentialReviewOrderByKeyRequest");
    credentialReviewService.getCredentialReviewOrderByKey(key).then(
      response => {
        commit("getCredentialReviewOrderByKeySuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderByKeyFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getCredentialReviewOrderProductsByKey({ dispatch, commit }, key) {
    commit("getCredentialReviewOrderProductsByKeyRequest");
    credentialReviewService.getCredentialReviewOrderProductsByKey(key).then(
      response => {
        commit("getCredentialReviewOrderProductsByKeySuccess", response);
      },
      error => {
        commit("getCredentialReviewOrderProductsByKeyFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getFormTemplate({ dispatch, commit }, id) {
    commit("formTemplateRequest");
    credentialReviewService.getFormtemplate(id).then(
      response => {
        commit("formTemplateSuccess", response);
      },
      error => {
        commit("formTemplateFailure");
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getIntegrations({ dispatch, commit }, id) {
    commit("integrationsRequest");
    credentialReviewService.getIntegrations(id).then(
      response => {
        commit("integrationsSuccess", response);
      },
      error => {
        commit("integrationsFailure");
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async submitForm({ dispatch, commit }, {toSend, trade, listType, exemId}) {
    commit("uploadDataRequest", {trade, listType, exemId});
    credentialReviewService.submitForm(toSend).then(
      response => {
        commit("uploadDataSuccess", {response, trade, listType, exemId});
      },
      error => {
        commit("uploadDataFailure", {error, trade, listType, exemId});
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  IDmeVerify({ dispatch, commit }, ID) {
    commit("verifyRequest");
    credentialReviewService.IDmeVerify(ID).then(
      response => {
        commit("verifySuccess", response);
      },
      error => {
        commit("verifyFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  //upload
  uploadData({ dispatch, commit }, {formData, trade, listType, exemId}) {
    commit("uploadDataRequest", {trade, listType, exemId});
    credentialReviewService.uploadData(formData).then(
      response => {
        commit("uploadDataSuccess", {response, trade, listType, exemId});
        return "Received";
      },
      error => {
        commit("uploadDataFailure", {error, trade, listType, exemId});
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  finalizeSubmission({ dispatch, commit }, path) {
    commit("finalizeSubmissionRequest");
    credentialReviewService.finalizeSubmission(path).then(
      response => {
        commit("finalizeSubmissionSuccess", response);
        return;
      },
      error => {
        commit("finalizeSubmissionFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  finalizeSubmissionNoEmail({ dispatch, commit }, path) {
    commit("finalizeSubmissionRequest");
    credentialReviewService.finalizeSubmissionNoEmail(path).then(
      response => {
        commit("finalizeSubmissionSuccess", response);
        return;
      },
      error => {
        commit("finalizeSubmissionFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
};

const mutations = {
  getCredentialReviewOrdersRequest(state) {
    state.reviewOrders.getOrdersLoading = true;
  },
  getCredentialReviewOrdersSuccess(state, response) {
    state.orders = {};
    for(const order of response)
    {
      state.orders[order.id] = order;
    }
    state.reviewOrders.getOrdersLoading = false;
    
  },
  getCredentialReviewOrdersFailure(state, error) {
    state.reviewOrders.getOrdersLoading = false;
    state.reviewOrders.order = error;

  },

  getCredentialReviewOrderPageCountRequest(state)
  {
    state.reviewOrders.getOrderPageCount = true;
  },
  getCredentialReviewOrderPageCountSuccess(state, response)
  {
    state.pageCount = response.count;
    state.reviewOrders.getOrderPageCount = false;
  },
  getCredentialReviewOrderPageCountFailure(state, error)
  {
    state.reviewOrders.getOrderPageCount = false;
  },

  getCredentialReviewOrderFastPassRequest(state)
  {
    state.reviewOrders.getCredentialReviewOrderFastPassLoading = true;
  },
  getCredentialReviewOrderFastPassSuccess(state, response)
  {
    state.FastPass = response;
    state.reviewOrders.getCredentialReviewOrderFastPassLoading = false;
  },
  getCredentialReviewOrderFastPassFailure(state, error)
  {
    state.reviewOrders.getCredentialReviewOrderFastPassLoading = false;
    
  },
  
  getCredentialReviewOrderFastPassOrderRequest(state)
  {
    //state.FastPassOrder = {};
    state.reviewOrders.getFastPassOrderLoading = true;
  },
  getCredentialReviewOrderFastPassOrderSuccess(state, response)
  {
    state.FastPassOrder = response;
    state.reviewOrders.getFastPassOrderLoading = false;
  },
  getCredentialReviewOrderFastPassOrderFailure(state, error)
  {
    state.reviewOrders.getFastPassOrderLoading = false;
    
  },

  adminFormTemplateRequest(state)
  {
    state.reviewOrders.adminFormTemplateLoading = true;
  },
  adminFormTemplateSuccess(state, response)
  {
    state.adminFormTemplate = response;
    state.adminFormTemplate.fields = JSON.parse(state.adminFormTemplate.fields);
    state.reviewOrders.adminFormTemplateLoading = false;
  },
  adminFormTemplateFailure(state, error)
  {
    state.reviewOrders.adminFormTemplateLoading = false;
    
  },

  uploadAdminFormRequest(state)
  {
    state.submitStatus = "Loading";
    state.reviewOrders.submissionLoading = true;
  },
  uploadAdminFormSuccess(state, response)
  {
    state.submitStatus = response.response === "" ? "Success" : response.response;
    state.adminSubmitStatus = response.status;
    state.reviewOrders.submissionLoading = false;
  },
  uploadAdminFormFailure(state, error)
  {
    state.submitStatus = error;
    state.reviewOrders.submissionLoading = false;
    
  },

  AdminUploadDataRequest(state)
  {
    state.submitStatus = "Loading";
    state.reviewOrders.submissionLoading = true;
  },
  AdminUploadDataSuccess(state, response)
  {
    state.submitStatus = response.response === "" ? "Success" : response.response;
    state.adminSubmitStatus = response.status;
    state.reviewOrders.submissionLoading = false;
  },
  AdminUploadDataFailure(state, error)
  {
    state.submitStatus = error;
    state.reviewOrders.submissionLoading = false;
    
  },

  getCredentialReviewOrderDetailsRequest(state) {
    state.reviewOrders.getOrderDetailsLoading = true;
  },
  getCredentialReviewOrderDetailsSuccess(state, response) 
  {
    const RuleSet = response;
    state.orderDetails[RuleSet.orderID] = {
      ID: RuleSet.orderID,
      BasicAndList: {},
      BasicOrList: {},
      ComplexOrList: {},
      OpenTrade: "0"
    }
    for(let ttype = 1; ttype < 4; ++ttype)
    {
      //adding an upload status to all exemptions
      if(RuleSet.tradeTypes[ttype])
      {
        for(const exem in RuleSet.tradeTypes[ttype].exemptions)
        {
          RuleSet.tradeTypes[ttype].exemptions[exem] = {...RuleSet.tradeTypes[ttype].exemptions[exem], UploadStatus: "None"};
        }
      }
      
      state.orderDetails[RuleSet.orderID].BasicAndList[ttype] = [];

      //setting up the ands
      if(RuleSet.tradeTypes[ttype] !== undefined)
      {
        for(const andRule in RuleSet.tradeTypes[ttype].andRules)//rewrite 
        {
          for(const andSet of RuleSet.tradeTypes[ttype].andRules[andRule].exemptionSets)
          {
            if(RuleSet.tradeTypes[ttype].andExemptionSets[andSet] !== undefined)
            {
              for(const exemID of RuleSet.tradeTypes[ttype].andExemptionSets[andSet].exemptions)
              {
                if(!state.orderDetails[RuleSet.orderID].BasicAndList[ttype].includes(RuleSet.tradeTypes[ttype].exemptions[exemID]))
                  state.orderDetails[RuleSet.orderID].BasicAndList[ttype].push(RuleSet.tradeTypes[ttype].exemptions[exemID]);
              }
            }
          }
        }
      }

      state.orderDetails[RuleSet.orderID].BasicOrList[ttype] = {};
      //setting up basic ors
      if(RuleSet.tradeTypes[ttype] !== undefined)
      {
        for(const andRule in RuleSet.tradeTypes[ttype].andRules)//rules
        {
          for(const orSet of RuleSet.tradeTypes[ttype].andRules[andRule].exemptionSets)//sets
          {
            if(RuleSet.tradeTypes[ttype].orExemptionSets[orSet] !== undefined)
            {
              if(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet] === undefined)
                state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet] = {id: orSet, exemptions: [], chosen: "", status: ""};
              for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[orSet].exemptions)//exemptions
              {
                if(!state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].exemptions.includes(RuleSet.tradeTypes[ttype].exemptions[exemID]))
                {
                  state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].exemptions.push(RuleSet.tradeTypes[ttype].exemptions[exemID]);
                  state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].status = CompoundStatuses(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].status, RuleSet.tradeTypes[ttype].exemptions[exemID].status);
                }
              }
                //Automatically select exemption 
              for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[orSet].exemptions)
              {
                if(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen === "" && RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Approved")
                {
                  state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen = exemID;
                }
              }
              if(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen === "")
              {
                for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[orSet].exemptions)
                {
                  if(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen === "" && RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Received")
                  {
                    state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen = exemID;
                  }
                }
              }
              if(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen === "")
              {
                for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[orSet].exemptions)
                {
                  if(state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen === "" && RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Denied")
                  {
                    state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen = exemID;
                  }
                  //if any are unsubmitted clear chosen only for denied
                  if(RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Unsubmitted")
                  {
                    state.orderDetails[RuleSet.orderID].BasicOrList[ttype][orSet].chosen = "";
                    break;
                  }
                }
              }
            }
          }
        }
      }

      state.orderDetails[RuleSet.orderID].ComplexOrList[ttype] = {};
      //setting up complex ors
      if(RuleSet.tradeTypes[ttype] !== undefined)
      {
        for(const orRule in RuleSet.tradeTypes[ttype].orRules)//rules
        {
          state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule] = {id: orRule, exemptionSets: {}, status: "", chosen: "", products: RuleSet.tradeTypes[ttype].orRules[orRule].products};
          for(const andorSet of RuleSet.tradeTypes[ttype].orRules[orRule].exemptionSets)//sets ^^CHANGE TO SETS 
          {
            if(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet] === undefined)//adds if set is not in the list yet
            {
              if(RuleSet.tradeTypes[ttype].andExemptionSets[andorSet] !== undefined)
              {
                state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet] = {id: andorSet, orExemptions: {}, andExemptions: {}, chosen: "", Name: RuleSet.tradeTypes[ttype].andExemptionSets[andorSet].name, products: RuleSet.tradeTypes[ttype].exemptions[Object.keys(RuleSet.tradeTypes[ttype].andExemptionSets[andorSet].exemptions)[0]], orChosen: ""};
              }
              else
              {
                state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet] = {id: andorSet, orExemptions: {}, andExemptions: {}, chosen: "", Name: RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].name, products: RuleSet.tradeTypes[ttype].exemptions[Object.keys(RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)[0]], orChosen: ""};
              }
            }
            if(RuleSet.tradeTypes[ttype].andExemptionSets[andorSet])
            {
              for(const exemID of RuleSet.tradeTypes[ttype].andExemptionSets[andorSet].exemptions)
              {
                state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].andExemptions[exemID] = RuleSet.tradeTypes[ttype].exemptions[exemID];
              }
            }
            
            if(RuleSet.tradeTypes[ttype].orExemptionSets[andorSet])
            {
              for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)
              {
                state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].orExemptions[exemID] = RuleSet.tradeTypes[ttype].exemptions[exemID];
                //state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].status = CompoundStatuses(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].orSets[andorSet].status, RuleSet.tradeTypes[ttype].exemptions[exemID].status);
              }
              //AUTO SELECT
              for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)
              {
                if(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen === "" && RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Approved")
                {
                  state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen = exemID;
                }
              }
              if(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen === "")
              {
                for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)
                {
                  if(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen === "" && RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Received")
                  {
                    state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen = exemID;
                  }
                }
              }
              if(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen === "")
              {
                for(const exemID of RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)
                {
                  if(state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen === "" && RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Denied")
                  {
                    state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen = exemID;
                  }
                  //if any are unsubmitted clear chosen only for denied
                  if(RuleSet.tradeTypes[ttype].exemptions[exemID].status === "Unsubmitted")
                  {
                    state.orderDetails[RuleSet.orderID].ComplexOrList[ttype][orRule].exemptionSets[andorSet].chosen = "";
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    //console.log(state.orderDetails)

    /*if(response[0] != null)
    {
      state.orderDetails[response[0].orderID + "_" + response[0].productID] = response;
    }*/
    state.reviewOrders.getOrderDetailsLoading = false;
  },
  getCredentialReviewOrderDetailsFailure(state, error) {
    state.reviewOrders.getOrderDetailsLoading = false;
    state.reviewOrders.orderDetails = error;
    

  },

  getOrderFulfillmentIntegrationsRequest(state)
  {
    state.OrderFulfillmentIntegrations = {};
    state.OrderFulfillmentIntegrationsLoading = true;
  },
  getOrderFulfillmentIntegrationsSuccess(state, response)
  {
    state.OrderFulfillmentIntegrations = response;
    state.OrderFulfillmentIntegrationsLoading = false;
  },
  getOrderFulfillmentIntegrationsFailure(state, error)
  {
    state.OrderFulfillmentIntegrationsLoading = false;
    
  },

  getCredentialReviewOrderAddressRequest(state) {
    state.reviewOrders.getOrderAddressLoading = true;
  },
  getCredentialReviewOrderAddressSuccess(state, response) {
    for(let i=0;i<2; i++){
      let x = "";
      x += response[i].address1 + ", "
      if(response[i].address2 != null && response[i].address2 != ''){
        x += response[i].address2 + ", "
      }
      x += response[i].city + ", "
      x += response[i].state + ", "
      x += response[i].postalCode
      if(response[i].id == 1){
        state.orderAddress.BillingAddress = x;
      }else{
        state.orderAddress.ShippingAddress = x
      }
    }
    state.reviewOrders.getOrderAddressLoading = false;
  },
  getCredentialReviewOrderAddressFailure(state, error) {
    state.reviewOrders.getOrderAddressLoading = false;
    //state.reviewOrders.orderAddress = error;
    

  },
  getCredentialReviewOrderProductsRequest(state){
    state.reviewOrders.getOrderProductLoading = true;
  },
  getCredentialReviewOrderProductsSuccess(state, response){
    state.orderProducts = []
    //state.orderProducts[response[0].orderID] = response
    for(const row of response)
    {
      state.orderProducts.push(row);
    }
    //state.orderProducts = [...response];
    state.reviewOrders.getOrderProductLoading = false;
  }
  ,
  getCredentialReviewOrderProductsFailure(state, error){
    state.reviewOrders.getOrderProductLoading = false;
    state.reviewOrders.orderProducts = error;
    
  },

  //Credential Retrieval
  RetrieveFileRequest(state)
  {
    state.imgString = "";
    state.imgFileID = 0;
    state.form = [];
    state.retrievalError = "";
    state.reviewOrders.retrieveFileLoading = true;
  },
  RetrieveFileSuccess(state, response)
  {
    if(response.file)
    {
      state.imgString = response.file.link;
      state.imgFileID = response.file.fileTypeID;
    }
    else
    {
      state.imgString = "";
      state.imgFileID = 0;
    }
    state.reviewOrders.retrieveFileLoading = false;
  },
  RetrieveFileFailure(state, error)
  {

    state.retrievalError = "No file found";

    state.reviewOrders.retrieveFileLoading = false;
    
  },

  RetrieveFormRequest(state)
  {
    state.imgString = ""
    state.form = [];
    state.retrievalError = "";
    state.reviewOrders.retrieveFormLoading = true;
  },
  RetrieveFormSuccess(state, response)
  {
    const keys = Object.keys(response)
    for(const field of keys)
    {
      state.form.push([field, response[field]])
    }
    state.reviewOrders.retrieveFormLoading = false;
  },
  RetrieveFormFailure(state, error)
  {
    state.retrievalError = "No form data found";
    state.reviewOrders.retrieveFormLoading = false;
    
  },

  DecideRequest(state)
  {
    state.reviewOrders.DecisionLoading = true;
  },
  DecideSuccess(state, {response, DecisionObject})
  {
    //state.orderDetails[DecisionObject.orderID]
    state.reviewOrders.DecisionLoading = false;
  },
  DecideFailure(state, error)
  {
    state.reviewOrders.DecisionLoading = false;
    
  },

  getCommentsRequest(state)
  {
    state.orderDetailComments = {};
    state.reviewOrders.commentsLoading = true;
  },
  getCommentsSuccess(state, response)
  {
    state.orderDetailComments = response;
    state.reviewOrders.commentsLoading = false;
  },
  getCommentsFailure(state, error)
  {
    state.reviewOrders.commentsLoading = false;
    
  },

  getOrderRuleSetRequest(state){
    state.reviewOrders.getOrderRuleSetLoading = true;
  },
  getOrderRuleSetSuccess(state, response ){
    state.RuleSet = response;
    state.iframeOrder["BasicAndList"] = {};
    state.iframeOrder["BasicOrList"] = {};
    state.iframeOrder["ComplexOrList"] = {};

    for(let ttype = 1; ttype < 4; ++ttype)
    {
      //adding an upload status to all exemptions
      if(state.RuleSet.tradeTypes[ttype])
      {
        for(const exem in state.RuleSet.tradeTypes[ttype].exemptions)
        {
          state.RuleSet.tradeTypes[ttype].exemptions[exem] = {...state.RuleSet.tradeTypes[ttype].exemptions[exem], UploadStatus: "None"};
        }
      }
      
      state.iframeOrder.BasicAndList[ttype] = [];

      //setting up the ands
      if(state.RuleSet.tradeTypes[ttype] !== undefined)
      {
        for(const andRule in state.RuleSet.tradeTypes[ttype].andRules)//rewrite 
        {
          for(const andSet of state.RuleSet.tradeTypes[ttype].andRules[andRule].exemptionSets)
          {
            if(state.RuleSet.tradeTypes[ttype].andExemptionSets[andSet] !== undefined)
            {
              for(const exemID of state.RuleSet.tradeTypes[ttype].andExemptionSets[andSet].exemptions)
              {
                if(!state.iframeOrder.BasicAndList[ttype].includes(state.RuleSet.tradeTypes[ttype].exemptions[exemID]))
                  state.iframeOrder.BasicAndList[ttype].push(state.RuleSet.tradeTypes[ttype].exemptions[exemID]);
              }
            }
          }
        }
      }

      state.iframeOrder.BasicOrList[ttype] = {};
      //setting up basic ors
      if(state.RuleSet.tradeTypes[ttype] !== undefined)
      {
        for(const andRule in state.RuleSet.tradeTypes[ttype].andRules)//rules
        {
          for(const orSet of state.RuleSet.tradeTypes[ttype].andRules[andRule].exemptionSets)//sets
          {
            if(state.RuleSet.tradeTypes[ttype].orExemptionSets[orSet] !== undefined)
            {
              if(state.iframeOrder.BasicOrList[ttype][orSet] === undefined)
                state.iframeOrder.BasicOrList[ttype][orSet] = {id: orSet, exemptions: [], chosen: "", status: ""};
              for(const exemID of state.RuleSet.tradeTypes[ttype].orExemptionSets[orSet].exemptions)//exemptions
              {
                if(!state.iframeOrder.BasicOrList[ttype][orSet].exemptions.includes(state.RuleSet.tradeTypes[ttype].exemptions[exemID]))
                {
                  state.iframeOrder.BasicOrList[ttype][orSet].exemptions.push(state.RuleSet.tradeTypes[ttype].exemptions[exemID]);
                  state.iframeOrder.BasicOrList[ttype][orSet].status = CompoundStatuses(state.iframeOrder.BasicOrList[ttype][orSet].status, state.RuleSet.tradeTypes[ttype].exemptions[exemID].status);
                }
              }
            }
          }
        }
      }

      state.iframeOrder.ComplexOrList[ttype] = {};
      //setting up complex ors
      if(state.RuleSet.tradeTypes[ttype] !== undefined)
      {
        for(const orRule in state.RuleSet.tradeTypes[ttype].orRules)//rules
        {
          state.iframeOrder.ComplexOrList[ttype][orRule] = {id: orRule, exemptionSets: {}, status: "", chosen: "", products: state.RuleSet.tradeTypes[ttype].orRules[orRule].products};
          for(const andorSet of state.RuleSet.tradeTypes[ttype].orRules[orRule].exemptionSets)//sets ^^CHANGE TO SETS 
          {
            if(state.iframeOrder.ComplexOrList[ttype][orRule].exemptionSets[andorSet] === undefined)//adds if set is not in the list yet
            {
              if(state.RuleSet.tradeTypes[ttype].andExemptionSets[andorSet] !== undefined)
              {
                state.iframeOrder.ComplexOrList[ttype][orRule].exemptionSets[andorSet] = {id: andorSet, orExemptions: {}, andExemptions: {}, chosen: "", Name: state.RuleSet.tradeTypes[ttype].andExemptionSets[andorSet].name, products: state.RuleSet.tradeTypes[ttype].exemptions[Object.keys(state.RuleSet.tradeTypes[ttype].andExemptionSets[andorSet].exemptions)[0]], orChosen: ""};
              }
              else
              {
                state.iframeOrder.ComplexOrList[ttype][orRule].exemptionSets[andorSet] = {id: andorSet, orExemptions: {}, andExemptions: {}, chosen: "", Name: state.RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].name, products: state.RuleSet.tradeTypes[ttype].exemptions[Object.keys(state.RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)[0]], orChosen: ""};
              }
            }
            if(state.RuleSet.tradeTypes[ttype].andExemptionSets[andorSet])
            {
              for(const exemID of state.RuleSet.tradeTypes[ttype].andExemptionSets[andorSet].exemptions)
              {
                state.iframeOrder.ComplexOrList[ttype][orRule].exemptionSets[andorSet].andExemptions[exemID] = state.RuleSet.tradeTypes[ttype].exemptions[exemID];
              }
            }
            
            if(state.RuleSet.tradeTypes[ttype].orExemptionSets[andorSet])
            {
              for(const exemID of state.RuleSet.tradeTypes[ttype].orExemptionSets[andorSet].exemptions)
              {
                state.iframeOrder.ComplexOrList[ttype][orRule].exemptionSets[andorSet].orExemptions[exemID] = state.RuleSet.tradeTypes[ttype].exemptions[exemID];
                //state.iframeOrder.ComplexOrList[ttype][orRule].exemptionSets[andorSet].status = CompoundStatuses(state.iframeOrder.ComplexOrList[ttype][orRule].orSets[andorSet].status, state.RuleSet.tradeTypes[ttype].exemptions[exemID].status);
              }
            }
          }
        }
      }
    }
    
    //add logic to do structure reversal to handle DUPECHANGE
    const MainExems = {1: {},2: {},3: {}};
    for(let ttype = 1; ttype < 4; ++ttype)//loops through the trade types
    {
        //console.log(state.iframeOrder.BasicAndList[ttype]);
        for(const exem of state.iframeOrder.BasicAndList[ttype])
        {
            if(!state.iframeOrder.ExemDupeTracker[ttype][exem.id])
            {
                state.iframeOrder.ExemDupeTracker[ttype][exem.id] = []
                state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, show: true, choice: true})
                MainExems[ttype][exem.id] = exem;
            }
            else
            {
                state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, show: true, choice: true})
                MainExems[ttype][exem.id].products = {...exem.products, ...MainExems[ttype][exem.id].products}
            }
        }

        for(const exemSet in state.iframeOrder.BasicOrList[ttype])
        {
            const currentSet = state.iframeOrder.BasicOrList[ttype][exemSet]
            for(const exem of currentSet.exemptions)
            {
                if(!state.iframeOrder.ExemDupeTracker[ttype][exem.id])
                {
                    state.iframeOrder.ExemDupeTracker[ttype][exem.id] = []
                    state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, AnyID: currentSet.id, show: true, choice: true})
                    MainExems[ttype][exem.id] = exem;
                }
                else
                {
                    state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, AnyID: currentSet.id, show: true, choice: true})
                    MainExems[ttype][exem.id].products = {...exem.products, ...MainExems[ttype][exem.id].products}
                }
            }
        }
        
        //state.iframeOrder.ComplexOrList[ttype]
        for(const exemRule in state.iframeOrder.ComplexOrList[ttype])
        {
            const CurRule = state.iframeOrder.ComplexOrList[ttype][exemRule]
            
            for(const exemSet in CurRule.exemptionSets)
            {
                const CurSet = CurRule.exemptionSets[exemSet]
                //ALL
                for(const exemId in CurSet.andExemptions)
                {
                    const exem = CurSet.andExemptions[exemId]
                    if(!state.iframeOrder.ExemDupeTracker[ttype][exem.id])
                    {
                        state.iframeOrder.ExemDupeTracker[ttype][exem.id] = [];
                        state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, OrID: exemRule, AndID: exemSet, show: true, choice: true})
                        MainExems[ttype][exem.id] = exem;
                    }
                    else
                    {
                        state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, OrID: exemRule, AndID: exemSet, show: true, choice: true})
                        MainExems[ttype][exem.id].products = {...exem.products, ...MainExems[ttype][exem.id].products}

                    }
                }

                //ANY
                for(const exemId in CurSet.orExemptions)
                {
                    const exem = CurSet.orExemptions[exemId]
                    if(!state.iframeOrder.ExemDupeTracker[ttype][exem.id])
                    {
                        state.iframeOrder.ExemDupeTracker[ttype][exem.id] = []
                        state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, OrID: exemRule, AnyID: exemSet, show: true, choice: true})
                        MainExems[ttype][exem.id] = exem;
                    }
                    else
                    {
                        state.iframeOrder.ExemDupeTracker[ttype][exem.id].push({id: exem.id, OrID: exemRule, AnyID: exemSet, show: true, choice: true})
                        MainExems[ttype][exem.id].products = {...exem.products, ...MainExems[ttype][exem.id].products}
                    }
                }
            }
        }
    }
    //console.log(state.iframeOrder.ExemDupeTracker)
    //console.log(state.iframeOrder.BasicOrList)

    state.reviewOrders.getOrderRuleSetLoading = false;
  },
  getOrderRuleSetFailure(state, error){
    state.reviewOrders.getOrderRuleSetLoading = false;
    
  },

  //for iframe
  getCredentialReviewOrderByKeyRequest(state){
    state.reviewOrders.getOrderByKeyLoading = true;
  },
  getCredentialReviewOrderByKeySuccess(state, response){
    state.iframeOrder.DateUTC = response.dateUTC;
    state.iframeOrder.ID = response.id;
    state.iframeOrder.Name = response.name;
    state.iframeOrder.CustomerID = response.customerID;
    state.iframeOrder.OrderID = response.orderID;
    state.iframeOrder.OutcomeType = response.outcomeType;
    state.iframeOrder.SystemDisplayOrderID = response.systemDisplayOrderID;
    state.iframeOrder.SubscriberID = response.subscriberID;

    state.reviewOrders.getOrderByKeyLoading = false;
  },
  getCredentialReviewOrderByKeyFailure(state, error){
    state.reviewOrders.getOrderByKeyLoading = false;
    
  },

  getCredentialReviewOrderProductsByKeyRequest(state){
    state.reviewOrders.getOrderProductsByKeyLoading = true;
  },
  getCredentialReviewOrderProductsByKeySuccess(state, response){
    state.iframeOrder.Products = {};
    for(const prd of response)
    {
      state.iframeOrder.Products[prd.id] = {
      Name: prd.name,
      ID: prd.id,
      SubscriberProductID: prd.subscriberProductID,
      };
    }
    state.reviewOrders.getOrderProductsByKeyLoading = false;
  }
  ,
  getCredentialReviewOrderProductsByKeyFailure(state, error){
    state.reviewOrders.getOrderProductsByKeyLoading = false;
    
  },

  formTemplateRequest(state)
  {
    state.reviewOrders.formTemplateLoading = true;
  },
  formTemplateSuccess(state, response)
  {
    state.FormTemplates = response;
    for(const template in state.FormTemplates)
    {
      state.FormTemplates[template].fields = JSON.parse(state.FormTemplates[template].fields.replace('\\', ''));
    }
    state.reviewOrders.formTemplateLoading = false;
  },
  formTemplateFailure(state, error)
  {
    state.reviewOrders.formTemplateLoading = false;
    
  },

  integrationsRequest(state)
  {
    state.reviewOrders.integrationsLoading = true;
  },
  integrationsSuccess(state, response)
  {
    state.integrations = response;
    state.reviewOrders.integrationsLoading = false;
  },
  integrationsFailure(state, error)
  {
    state.reviewOrders.integrationsLoading = false;
    
  },

  submitFormRequest(state)
  {
    state.reviewOrders.submitFormLoading = true;
  },
  submitFormSuccess(state, response)
  {
    state.reviewOrders.submitFormLoading = false;
    //need to search the exemptions and update the status to received
  },
  submitFormFailure(state, error)
  {
    state.reviewOrders.submitFormLoading = false;
    
  },

  verifyRequest(state)
  {
    state.reviewOrders.IDmeVerifyLoading = true;
  },
  verifySuccess(state, response)
  {
    state.currentExemStatus = response.status;
    state.reviewOrders.IDmeVerifyLoading = false;
  },
  verifyFailure(state, error)
  {
    state.reviewOrders.IDmeVerifyLoading = false;
    
  },

  //uploads
  uploadDataRequest(state, {trade, listType, exemId})
  {
    state.reviewOrders.uploadDataLoading = true;
    state.submitStatus = "Loading"
    /*switch(listType)
    {
      case "and":
        state.iframeOrder.BasicAndList[trade].find(exem => exem.id == exemId).UploadStatus = "Loading";
        break;
      case "sor":
        console.log(state.iframeOrder.BasicOrList[trade]);
        state.iframeOrder.BasicOrList[trade].find(set => set.exemptions.find(exem => exem.id === exemId)).exemptions[exemId].UploadStatus = "Loading"
        break;
      case "cor":
        {
          const exemInfo = complexExemFinder(state, trade, exemId);
          switch(exemInfo.AndOr)
          {
            case "and":
              state.iframeOrder.ComplexOrList[trade][exemInfo.OrRule].exemptionSets[exemInfo.SetID].andExemptions[exemId].UploadStatus = "Loading";
              break;
            case "or":
              state.iframeOrder.ComplexOrList[trade][exemInfo.OrRule].exemptionSets[exemInfo.SetID].orExemptions[exemId].UploadStatus = "Loading";
              break;
          }
        }
        break;
    }*/
  },
  uploadDataSuccess(state, {response, trade, listType, exemId})
  {
    state.submitStatus =  response.response === "" ? "Success" : response.response;
    state.currentExemStatus = response.status;
    if(response.docResponse === "Doc Found")
    {
      const FLLs = [];
      //And All'S
      for(let i = 1; i < 4; ++i)
      {
        FLLs.push(...state.iframeOrder.BasicAndList[i].filter((e) => e.exemptionTypeID == 4));
      }
      //And Any's
      for(let i = 1; i < 4; ++i)
      {
        i++;
        i--;//FLLs.push(...state.iframeOrder.BasicAndList[i].filter((e) => e.exemptionTypeID == 4));
      }
      //Or's
      for(let i = 1; i < 4; ++i)
      {
        for(const rule in state.iframeOrder.ComplexOrList[i])//all rules
        {
          for(const set in state.iframeOrder.ComplexOrList[i][rule].exemptionSets)//all exem sets
          {
            for(const exem in state.iframeOrder.ComplexOrList[i][rule].exemptionSets[set].andExemptions)
            {
              if(state.iframeOrder.ComplexOrList[i][rule].exemptionSets[set].andExemptions[exem].exemptionTypeID == 4)
                FLLs.push(state.iframeOrder.ComplexOrList[i][rule].exemptionSets[set].andExemptions[exem])
            }
            for(const exem in state.iframeOrder.ComplexOrList[i][rule].exemptionSets[set].orExemptions)
            {
              if(state.iframeOrder.ComplexOrList[i][rule].exemptionSets[set].orExemptions[exem].exemptionTypeID == 4)
                FLLs.push(...state.iframeOrder.ComplexOrList[i][rule].exemptionSets[set].orExemptions[exem])
            }
          }
        }
      }
      
      for(const license of FLLs)
      {
        license.status = "Accepted";
        
      }
    }
    /*switch(listType)
    {
      case "and":
        state.iframeOrder.BasicAndList[trade].find(exem => exem.id == exemId).UploadStatus = response.response === "" ? "Success" : response.response;
        state.iframeOrder.BasicAndList[trade].find(exem => exem.id == exemId).status = response.status;
        break;
      case "sor":
        state.iframeOrder.BasicOrList[trade].find(set => set.hasOwn(exemId))[exemId].UploadStatus = response.response === "" ? "Success" : response.response;
        state.iframeOrder.BasicOrList[trade].find(set => set.hasOwn(exemId))[exemId].status = response.status;;
        break;
      case "cor":
        {
          const exemInfo = complexExemFinder(state, trade, exemId);
          switch(exemInfo.AndOr)
          {
            case "and":
              state.iframeOrder.ComplexOrList[trade][exemInfo.OrRule].exemptionSets[exemInfo.SetID].andExemptions[exemId].UploadStatus = response.response === "" ? "Success" : response.response;
              state.iframeOrder.ComplexOrList[trade][exemInfo.OrRule].exemptionSets[exemInfo.SetID].andExemptions[exemId].status = response.status;
              break;
            case "or":
              state.iframeOrder.ComplexOrList[trade][exemInfo.OrRule].exemptionSets[exemInfo.SetID].orExemptions[exemId].UploadStatus = response.response === "" ? "Success" : response.response;
              state.iframeOrder.ComplexOrList[trade][exemInfo.OrRule].exemptionSets[exemInfo.SetID].orExemptions[exemId].status = response.status;
              break;
          }
        }
        break;
    }*/
    state.reviewOrders.uploadDataLoading = false;
  },
  uploadDataFailure(state, {error, trade, listType, exemId})
  {
    alert("Failure: " + error);
    state.submitStatus = error;
    state.reviewOrders.uploadDataLoading = false;
    
  },

  finalizeSubmissionRequest(state)
  {
    state.reviewOrders.finalizeSubmissionLoading = true;
  },
  finalizeSubmissionSuccess(state, response)
  {
    state.reviewOrders.finalizeSubmissionLoading = false;
  },
  finalizeSubmissionFailure(state, error)
  {
    state.reviewOrders.finalizeSubmissionLoading = false;
    
  },

  updateLinkExpirationRequest(state)
  {
    state.reviewOrders.updateLinkExpirationLoading = true;
  },
  updateLinkExpirationSuccess(state, response)
  {
    state.reviewOrders.updateLinkExpirationLoading = false;
  },
  updateLinkExpirationFailure(state, error)
  {
    state.reviewOrders.updateLinkExpirationLoading = false;
    
  },

  ResetSubmitStatus(state)
  {
    state.submitStatus = "None";
  },
  ResetOrderInfo(state)
  {
    state.orderAddress.BillingAddress = '';
    state.orderAddress.ShippingAddress = '';
    state.orderProducts = [];
  },
  ResetFormData(state)
  {
    state.form = [];
    state.imgString = "";
  }

};

export const credentialReview = {
  namespaced: true,
  state,
  actions,
  mutations
};
